<template>
<div>
  <div><org-change @orgChange="orgChange"/></div>

  <div>
    <div class="container">
      <title-cop  :title="`${isStreet ? '街道' : '社区'}房屋情况`" :tip="cqhShow ? '拆迁户不计入房屋总数' : ''" />
      <div class="user_status"><total-number :countList="informationList" :resCount="informationCount" :countName="'房屋总数'" :unit="'户'" /></div>
    </div>

    <div class="container">
      <title-cop  :title="`${isStreet ? '街道各社区' : '社区各网格' }房屋类型统计`"/>
      <div class="user_age"><stack-bar v-if="stackBarShow" ref="stackBar" :labelName="nameList" :dataList="valueList"/></div>
    </div>

    <div class="container">
      <title-cop  :title="`${isStreet ? '街道' : '社区' }近半年房屋数据统计`"/>
      <div class="peple_change"><line-cop :labelName="monthName" :countList="monthValue" /></div>
    </div>
  </div>
  <div style="height: 20px"></div>
</div>
</template>

<script>
import orgChange from './components/orgChange.vue'
import SexProgress from './components/sex-progress.vue'
import titleCop from './components/title-cop'
import totalNumber from './components/total-Number'
import UserType from './components/userType.vue'
import widthBar from './components/widthBar.vue'
import lineCop from './components/line-cop.vue'
import stackBar from './components/stackBar.vue'
export default {
  components: {
    titleCop,
    totalNumber,
    SexProgress,
    orgChange,
    widthBar,
    UserType,
    lineCop,
    stackBar
  },
  data () {
    return {
      orgId: 0,
      isStreet: true,
      stackBarShow: false,
      orgList: [],
      informationList: [],
      informationCount: 0,
      nameList: [],
      valueList: [],
      monthName: [],
      monthValue: [],
      cqhShow: false
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.getInformation()
      this.getTypeInformation()
      this.getSixMonth()
    },
    orgChange (id, isStreet) {
      this.orgId = id
      this.isStreet = isStreet
      this.informationList = [],
      this.informationCount = 0,
      this.nameList = [],
      this.valueList = [],
      this.monthName = [],
      this.monthValue = []
      this.init()
    },
    getInformation () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/statistics/house/information'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.orgId || this.$globalData.userInfo.orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          let count = Object.values(data.data).reduce((a,b)=> a+b)
          count = count - data.data.cqhNum
          let per1, per2, per3, per4, per5, per6, per7
          per1 = data.data.zzNum ? ((data.data.zzNum / count) * 100).toFixed(2) : 0
          per2 = data.data.syNum ? ((data.data.syNum / count) * 100).toFixed(2) : 0
          per3 = data.data.czNum ? ((data.data.czNum / count) * 100).toFixed(2) : 0
          per4 = 0
          per5 = data.data.bzzbczNum ? ((data.data.bzzbczNum / count) * 100).toFixed(2) : 0
          per6 = data.data.kzfNum ? ((data.data.kzfNum / count) * 100).toFixed(2) : 0
          per7 = data.data.wzNum ? ((data.data.wzNum / count) * 100).toFixed(2) : 0
          this.informationList = [
            {name: '自住', value: data.data.zzNum, percent: per1 + '%'},
            {name: '商业', value: data.data.syNum, percent: per2 + '%'},
            {name: '出租', value: data.data.czNum, percent: per3 + '%'},
            {name: '半自住半出租', value: data.data.bzzbczNum, percent: per5 + '%'},
            {name: '空置房', value: data.data.kzfNum, percent: per6 + '%'},
            {name: '未知', value: data.data.wzNum, percent: per7 + '%'},
          ]
          if (data.data.cqhNum != 0) {
            this.cqhShow = true
            per4 = data.data.bzzbczNum ? ((data.data.cqhNum / count) * 100).toFixed(2) : 0
            this.informationList.push({name: '拆迁户', value: data.data.cqhNum, percent: per4 + '%'})
          }
          this.informationCount = count
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getTypeInformation () {
      this.stackBarShow = false
      this.$http({
        url: this.$http.adornUrl('/wxapp/statistics/house/typeInformation'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.orgId || this.$globalData.userInfo.orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          let showCqh = false
          data.data.forEach(item => {
            for (let key in item.parameters) {
              if (item.parameters[key].useTypeName == '拆迁户' && item.parameters[key].num != 0) {
                showCqh = true
              }
            }
          })
          this.nameList = data.data.map(item => item.label)
          for (const key in data.data[0].parameters) {
            let vals =[]
            if (data.data[0].parameters[key].useTypeName !== '拆迁户' || showCqh) {
              data.data.map(item => {
                vals.push(item.parameters[key].num)
              })
              this.valueList.push({name: data.data[0].parameters[key].useTypeName, value: vals})
            }
          }
          // for (let key in data.data[0].parameters) {
          //   if (data.data[0].parameters[key].useTypeName !== '拆迁户' || showCqh) {
          //     this.nameList.push(data.data[0].parameters[key].useTypeName)
          //   }
          // }
          // this.valueList = data.data.map(item => {
          //   let vals = []
          //   for (let key in item.parameters) {
          //     if (item.parameters[key].useTypeName !== '拆迁户' || showCqh) {
          //       vals.push(item.parameters[key].num)
          //     }
          //   }
          //   return {
          //     name: item.label,
          //     value: vals
          //   }
          // })
          this.stackBarShow = true
          this.$nextTick(() => {
            this.$refs.stackBar.initChartBar()
          })
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getSixMonth () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/statistics/house/sixMonth'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.orgId || this.$globalData.userInfo.orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          let monthName = data.data.zz.map(item => item.month)
          let monthValue = [{name: '自住', value: []}, {name: '出租', value: []}, {name: '其他', value: []},]
          monthValue[0].value = data.data.zz.map(item => item.houseCount)
          monthValue[1].value = data.data.cz.map(item => item.houseCount)
          monthValue[2].value = data.data.qt.map(item => item.houseCount)
          this.monthValue = monthValue
          this.monthName = monthName
        } else {
          this.$toast.fail(data.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.topOrgBox {
  padding: 20px 30px;
  .topOrg {
    width: 100%;
    height: 74px;
    text-align: center;
    line-height: 74px;
    margin: 0;
    border-radius: 10px;
    background-color: #fff;
    .pull-icon {
        width: 10px;
    }
  }
}
.container {
  background-color: #fff;
  margin-bottom: 20px;
}
.split_line {
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 2px solid #eee;
}
.user_status {
  height: 500px;
}
.peple_change {
  height: 500px;
}
</style>
